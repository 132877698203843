import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import {APIClient} from "../../../helpers/api_helper";
import {BASE_API_URL} from "../../../config/constants";

const api = new APIClient();

export const processPostAuthMe = createAsyncThunk("processPostAuthMe", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = api.create(`${BASE_API_URL}auth/me`)
    const result = await response;

    if(result){
      return result;
    }
  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});