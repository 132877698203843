import { createSlice } from "@reduxjs/toolkit";
import {ASYNC_STATUSES, DEFAULT_REDUCER_INITIAL_VALUE} from "../../../config/constants";
import {processPostSignOut} from "./thunk";

const initialState = DEFAULT_REDUCER_INITIAL_VALUE;
const signOutStore = createSlice({
  name: "signOutStore",
  initialState,
  extraReducers: (builder) => {
    builder
        .addCase(processPostSignOut.pending, (state, action) => {
          state.isLoading = true;
          state.isError = false;
          state.statusAction = ASYNC_STATUSES.pending;
          state.showNotification.isShow = false;
          state.showNotification.notificationMessage = "";
        })
        .addCase(processPostSignOut.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.statusAction = ASYNC_STATUSES.fullfilled;
          state.resultset = action.payload;
        })
        .addCase(processPostSignOut.rejected, (state, action) => {
          const unstringifyError = JSON.parse(action.payload)

          state.isLoading = false;
          state.isError = true;
          state.statusAction = ASYNC_STATUSES.reject;
          state.httpCode = unstringifyError.status;
          state.errorset = unstringifyError.data.message;
          state.showNotification.isShow = true;
        });
  }
})

export default signOutStore.reducer;