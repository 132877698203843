import { combineReducers } from "redux";

import SignInReducer from "../states/authentication/signin/reducer";
import SignOutReducer from "../states/authentication/signout/reducer";
import RegisterReducer from "../states/authentication/register/reducer";
import RegisterOnlineReducer from "../states/authentication/register-online/reducer";
import RegisterOnlineActualizationReducer from "../states/authentication/register-online-actualization/reducer";
import RegisterProfileReducer from "../states/authentication/register-profile/reducer";
import ProfileReducer from "../states/authentication/profile/reducer";

const rootReducer = combineReducers({
  SignIn: SignInReducer,
  SignOut: SignOutReducer,
  Register: RegisterReducer,
  RegisterOnline: RegisterOnlineReducer,
  RegisterOnlineActualization: RegisterOnlineActualizationReducer,
  RegisterProfile: RegisterProfileReducer,
  Profile: ProfileReducer,
})

export default rootReducer;