import React, {useEffect, useRef, useState} from 'react';
import withRouter from "../../Components/Common/withRouter";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink,
  Row, TabContent, TabPane,
} from "reactstrap";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import Flatpickr from "react-flatpickr";
import country from "../../Components/Common/CountryData";
import classnames from "classnames";

import { FilePond,  registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import {
  calculateAge,
  generateDateWithPreviousYear,
  generateToastifyError,
  getSessionStorage
} from "../../helpers/general_helper";
import {useDispatch, useSelector} from "react-redux";
import {
  processGetMasterAldeia,
  processGetMasterDistricts,
  processGetMasterSubdistricts, processGetMasterSucco, processPostCheckElector, processPostRegisterOnline
} from "../../states/authentication/register-online/thunk";
import Select from 'react-select'
import SimpleBar from "simplebar-react";
import {useFormik} from "formik";
import {Link} from "react-router-dom";
import {GUEST_REGISTER, GUEST_REGISTER_ACTUALIZATION, GUEST_REGISTER_STATUS} from "../../config/url";
import {useTranslation} from "react-i18next";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const RegisterOnline = (props) => {
  const {actionOnInputPortugueseFocused, actionOnInputPortugueseChange, actionOnInputPortugueseValue} = props;
  const dispatch = useDispatch();
  const registerOnlineStore = useSelector((state) => state.RegisterOnline);
  const statusRegister = 0;
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(processGetMasterDistricts())
    dispatch(processPostCheckElector());
  }, []);

  const [showModalNotification, setShowModalNotification] = useState(false);
  const [showModalAlreadyRegistered, setShowModalAlreadyRegistered] = useState(false);

  const [inputCommonData, setInputCommonData] = useState({});
  const [usersProps, setUserProps] = useState({});
  const [listRegions, setListRegions] = useState({});
  const [isBornInTimor, setIsBornInTimor] = useState(true);
  const [fileDocuments, setFileDocuments] = useState([]);
  const [stateSteps, setStateSteps] = useState({
    activeStep: 4,
    passedStep: [1]
  });

  const [dropdownCountryCode, setDropdownCountryCode] = useState(false);

  useEffect(() => {
    setUserProps(props.fromMe);
  }, [props]);

  useEffect(() => {
    setInputCommonData({
      inputDOB: generateDateWithPreviousYear(),
      inputDateRegistration: new Date(),
      inputGender: 'M'
    })
  }, [])


  /* Reff */
  const inputRefs = useRef({});

  const toggleStep = (tab) => {
    if (stateSteps['activeStep'] !== tab) {
      var modifiedSteps = [...stateSteps['passedStep'], tab];

      if (tab >= 4 && tab <= 7) {
        setStateSteps(prevState => ({
          ...prevState,
          activeStep: tab,
          passedStep: modifiedSteps
        }));
      }
    }
  }

  const validationSubmit = useFormik({
    enableReinitialize: true,

    initialValues: {

    },
    onSubmit: () => {
      const inputFiles = [];
      fileDocuments.forEach((singleFile) => {
        inputFiles.push(singleFile.file);
      })

      const fromRegister = {
        email: usersProps.email,
        fullname: usersProps.fullname,
        itx: usersProps.itx,
      }
      const inputTextValue = {...inputCommonData, ...actionOnInputPortugueseValue, ...fromRegister};
      const passToBindData = {
        textValues: inputTextValue,
        fileDocuments: inputFiles
      }

      const data = {
        bindData: passToBindData,
        routerData: props.router
      }


      dispatch(processPostRegisterOnline(data))
    }
  });

  const handleAction = {
    toggleCountryCode: () => setDropdownCountryCode((prevState) => !prevState),
    toggleIsBornInTimor: (e) => {
      setIsBornInTimor(!isBornInTimor);
    },
    onInputCommonDataChange: (inputId, value, isInputRegion=false) => {

      if(inputId === 'inputDOB' && calculateAge(value) < 17){
        generateToastifyError('Age must be >= 17');
        return false;
      }

      const listStateRegion = {
        inputDomicileDistrict: {
          inputDomicileSubDistrict: null,
          inputDomicileSucco: null,
          inputDomicileAldeia: null,
        },
        inputDomicileSubDistrict: {
          inputDomicileSucco: null,
          inputDomicileAldeia: null,
        },
        inputDomicileSucco: {
          inputDomicileAldeia: null,
        },
        inputDomicileAldeia: {},
        inputOriginDistrict: {
          inputOriginSubDistrict: null,
          inputOriginSucco: null,
          inputOriginAldeia: null,
        },
        inputOriginSubDistrict: {
          inputOriginSucco: null,
          inputOriginAldeia: null,
        },
        inputOriginSucco: {
          inputOriginAldeia: null,
        },
        inputOriginAldeia: {},
      }

      setInputCommonData(prevState => ({
        ...prevState,
        [inputId]: value,
        ...listStateRegion[inputId]
      }));

      if(isInputRegion){
        const listBehavior = {
          'inputDomicileDistrict': {
            dispatcher: (data) => processGetMasterSubdistricts(data),
            bindData: {selectedDistrict: value},
            nextDataRegion: 'inputDomicileSubDistrict'
          },
          'inputDomicileSubDistrict': {
            dispatcher: (data) => processGetMasterSucco(data),
            bindData: {selectedSubdistrict: value},
            nextDataRegion: 'inputDomicileSucco'
          },
          'inputDomicileSucco': {
            dispatcher: (data) => processGetMasterAldeia(data),
            bindData: {selectedSucco: value},
            nextDataRegion: 'inputDomicileAldeia'
          },

          'inputOriginDistrict': {
            dispatcher: (data) => processGetMasterSubdistricts(data),
            bindData: {selectedDistrict: value},
            nextDataRegion: 'inputOriginSubDistrict'
          },
          'inputOriginSubDistrict': {
            dispatcher: (data) => processGetMasterSucco(data),
            bindData: {selectedSubdistrict: value},
            nextDataRegion: 'inputOriginSucco'
          },
          'inputOriginSucco': {
            dispatcher: (data) => processGetMasterAldeia(data),
            bindData: {selectedSucco: value},
            nextDataRegion: 'inputOriginAldeia'
          },
        }

        const hasBehavior = inputId in listBehavior;
        if(hasBehavior){
          const getBehavior = listBehavior[inputId];

          const data = {
            bindData: getBehavior['bindData'],
            routerData: props.router,
            typeRegion: getBehavior['nextDataRegion']
          }

          dispatch(getBehavior['dispatcher'](data))
        }
      }
    },
    onFocusInputRegion: (inputId,typeRegion) => {
      const getRegionFromStore = registerOnlineStore.resultset.region;
      const setKeyName = `${inputId}_list`;

      setListRegions(prevState => ({
        ...prevState,
        [setKeyName]: getRegionFromStore[inputId]
      }));
    },
    onSubmit: (e) => {
      e.preventDefault();
      console.log(inputCommonData)
      if(Object.entries(inputCommonData).length === 0) {
        generateToastifyError('Input must be filled')
      } else if(!inputCommonData.hasOwnProperty("inputDomicileDistrict")){
        generateToastifyError('Input Residence Municipiu must be filled');
      } else if(!inputCommonData.hasOwnProperty("inputDomicileSubDistrict")){
        generateToastifyError('Input Residence Posto Administrativu must be filled');
      } else if(!inputCommonData.hasOwnProperty("inputDomicileSucco")){
        generateToastifyError('Input Residence Succo must be filled');
      } else if(!inputCommonData.hasOwnProperty("inputDomicileAldeia")){
        generateToastifyError('Input Residence Aldeia must be filled');
      } else if(!actionOnInputPortugueseValue.hasOwnProperty("inputFatherName")){
        generateToastifyError('Input Aman nia Naran must be filled');
      } else if(!actionOnInputPortugueseValue.hasOwnProperty("inputMotherName")){
        generateToastifyError('Input Residence Inan Nia Naran must be filled');
      } else if(isBornInTimor && !inputCommonData.hasOwnProperty("inputOriginDistrict")){
        generateToastifyError('Input Origin Municipiu must be filled');
      } else if(isBornInTimor && !inputCommonData.hasOwnProperty("inputOriginSubDistrict")){
        generateToastifyError('Input Origin Posto Administrativu must be filled');
      } else if(isBornInTimor && !inputCommonData.hasOwnProperty("inputOriginSucco")){
        generateToastifyError('Input Origin Succo must be filled');
      } else if(isBornInTimor && !inputCommonData.hasOwnProperty("inputOriginAldeia")){
        generateToastifyError('Input Origin Aldeia must be filled');
      } else if(!isBornInTimor && !inputCommonData.hasOwnProperty("inputBornInOtherCountry")) {
        generateToastifyError('Option Select Country must be selected');
      } else if (!isBornInTimor && !inputCommonData.hasOwnProperty("inputBornInOtherCity")) {
        generateToastifyError('Input City must be filled');
      } else if(!isBornInTimor && !actionOnInputPortugueseValue.hasOwnProperty("inputOriginAddress")){
        generateToastifyError('Input Origin Address must be filled');
      } else if(!actionOnInputPortugueseValue.hasOwnProperty("inputNameDocument")){
        generateToastifyError('Input Name Document must be filled');
      } else if(!inputCommonData.hasOwnProperty("inputNoDocument")){
        generateToastifyError('Input No. Document must be filled');
      } else if(!inputCommonData.hasOwnProperty("inputIssuedAtDocument")){
        generateToastifyError('Input Document Issued At must be filled');
      } else if(!inputCommonData.hasOwnProperty("inputIssuedInDocument")){
        generateToastifyError('Input Document Issued In must be filled');
      } else {
        validationSubmit.handleSubmit();
      }
    }
  };

  useEffect(() => {
    setShowModalNotification(registerOnlineStore.showNotification.isShow)
    if(registerOnlineStore.isProcessDone){
      if(registerOnlineStore.resultset.dataElectorRegistered !== undefined && registerOnlineStore.resultset.dataElectorRegistered !== null){
        setShowModalAlreadyRegistered(true)
      }
    }
  }, [registerOnlineStore]);

  return (
      <>
        <div className="page-content">
          <Container fluid>
            <CustomBreadcrumb title={t('breadcrumb_register_elector')} pageTitle="Pages"/>
            <Row>
              <Col xl={12}>
                <Card className="rounded-0">
                  <PreviewCardHeader title="Input Elector Registration"/>
                  <CardBody className="card-body">
                    <form autoComplete="off" onSubmit={(e) => handleAction.onSubmit(e)}>
                      <div className="step-arrow-nav mb-4">
                        <Nav
                          className="nav-pills custom-nav nav-justified"
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              href="#"
                              id="steparrow-gen-info-tab"
                              className={classnames({
                                active: stateSteps['activeStep'] === 4,
                                done: stateSteps['activeStep'] <= 6 && stateSteps['activeStep'] > 3,
                              })}
                              onClick={() => {
                                toggleStep(4);
                              }}
                            >
                              Base Information
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              href="#"
                              id="steparrow-gen-info-tab"
                              className={classnames({
                                active: stateSteps['activeStep'] === 5,
                                done: stateSteps['activeStep'] <= 6 && stateSteps['activeStep'] > 4,
                              })}
                              onClick={() => {
                                toggleStep(5);
                              }}
                            >
                              Biography
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>

                      <TabContent activeTab={stateSteps['activeStep']}>
                        <TabPane id="steparrow-gen-info" tabId={4}>
                          <div>
                            <Row className="gy-0">
                              <Col xl={3} className="mb-3">
                                <div>
                                  <Label htmlFor="inputNoEleitor" className="form-label mb-1">No. Eleitor</Label>
                                  <Input type="number" className="form-control rounded-0" id="inputNoEleitor"
                                         autoFocus/>
                                </div>
                              </Col>
                              <Col xl={9} className="mb-0"/>
                              <Col xl={6} className="mb-0">
                                <div>
                                  <Label htmlFor="inputNoEleitor" className="form-label mb-1">Photo Card Elector</Label>
                                  <FilePond
                                    id="inputFileCardElector"
                                    files={fileDocuments}
                                    onupdatefiles={setFileDocuments}
                                    allowMultiple={false}
                                    maxFiles={1}
                                    maxFileSize="2MB"
                                    name="document"
                                    className="filepond filepond-input-multiple"
                                    acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                                    labelIdle="Select a Image file with a size of 2MB"
                                  />
                                </div>
                              </Col>
                              <Col xl={9} />
                              <Col xl={6}>
                                <div>
                                  <Label htmlFor="inputNoEleitor" className="form-label mb-1">Photo Profile</Label>
                                  <FilePond
                                    id="inputFilePhotoProfile"
                                    files={fileDocuments}
                                    onupdatefiles={setFileDocuments}
                                    allowMultiple={true}
                                    maxFiles={1}
                                    maxFileSize="2MB"
                                    name="document"
                                    className="filepond filepond-input-multiple"
                                    acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                                    labelIdle="Select a Image file with a size of 2MB"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                              type="button"
                              className="btn btn-primary rounded-0 btn-label right ms-auto nexttab nexttab"
                              onClick={() => {
                                toggleStep(stateSteps['activeStep'] + 1);
                              }}
                            >
                              <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                              Go to Biography
                            </button>
                          </div>
                        </TabPane>

                        <TabPane id="steparrow-description-info" tabId={5}>
                          <Row className="mb-2">
                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputDomicileDistrict" className="form-label mb-1">Municipiu</Label>
                                <Select id="inputDomicileDistrict" classNamePrefix="select-region"
                                        options={listRegions['inputDomicileDistrict_list']}
                                        value={listRegions['inputDomicileDistrict_list']?.find(option => option.value === inputCommonData.inputDomicileDistrict) || null}
                                        onChange={(e) => handleAction.onInputCommonDataChange('inputDomicileDistrict', e.value, true)}
                                        onFocus={(e) => handleAction.onFocusInputRegion('inputDomicileDistrict', 'districts')}
                                />
                              </div>
                            </Col>

                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputDomicileSubDistrict" className="form-label mb-1">Posto
                                  Administrativu</Label>
                                <Select id="inputDomicileSubDistrict" className="rounded-0"
                                        options={listRegions['inputDomicileSubDistrict_list']}
                                        value={listRegions['inputDomicileSubDistrict_list']?.find(option => option.value === inputCommonData.inputDomicileSubDistrict) || null}
                                        onChange={(e) => handleAction.onInputCommonDataChange('inputDomicileSubDistrict', e.value, true)}
                                        onFocus={(e) => handleAction.onFocusInputRegion('inputDomicileSubDistrict', 'subdistricts')}
                                />
                              </div>
                            </Col>

                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputDomicileSucco" className="form-label mb-1">Succo</Label>
                                <Select id="inputDomicileSucco" className="rounded-0"
                                        options={listRegions['inputDomicileSucco_list']}
                                        value={listRegions['inputDomicileSucco_list']?.find(option => option.value === inputCommonData.inputDomicileSucco) || null}
                                        onChange={(e) => handleAction.onInputCommonDataChange('inputDomicileSucco', e.value, true)}
                                        onFocus={(e) => handleAction.onFocusInputRegion('inputDomicileSucco', 'succo')}
                                />
                              </div>
                            </Col>

                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputDomicileAldeia" className="form-label mb-1">Aldeia</Label>
                                <Select id="inputDomicileAldeia" className="rounded-0"
                                        options={listRegions['inputDomicileAldeia_list']}
                                        value={listRegions['inputDomicileAldeia_list']?.find(option => option.value === inputCommonData.inputDomicileAldeia) || null}
                                        onChange={(e) => handleAction.onInputCommonDataChange('inputDomicileAldeia', e.value, true)}
                                        onFocus={(e) => handleAction.onFocusInputRegion('inputDomicileAldeia', 'aldeia')}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="gy-2">
                            <Col xl={3}>
                              <div>
                                <Label htmlFor="inputFullname" className="form-label mb-1">Naran Kompletu</Label>
                                <Input type="text" className="form-control rounded-0" id="inputFullname"
                                       autoFocus value={usersProps.fullname}/>
                              </div>
                            </Col>

                            <Col xl={3}>
                              <div>
                                <Label htmlFor="inputFullname" className="form-label mb-1">Enderesu
                                  Eletroniku</Label>
                                <Input type="email" className="form-control rounded-0" id="inputFullname"
                                       value={usersProps.email} disabled/>
                              </div>
                            </Col>

                            <Col xl={3}>
                              <div>
                                <Label htmlFor="inputFullname" className="form-label mb-1">Numeru Telefone</Label>
                                <Input type="text" className="form-control rounded-0" id="inputFullname"
                                       value={usersProps.phone_number} disabled/>
                              </div>
                            </Col>

                            <Col xl={3}>
                              <div>
                                <Label htmlFor="inputDateRegistration" className="form-label mb-1">Loron
                                  Rejistu</Label>
                                <Flatpickr
                                  id="inputDateRegistration"
                                  tabIndex={1}
                                  className="form-control rounded-0"
                                  options={{
                                    dateFormat: "d M, Y",
                                    defaultDate: new Date(),
                                  }}
                                  onChange={([date]) => handleAction.onInputCommonDataChange('inputDateRegistration', date)}
                                />
                              </div>
                            </Col>

                            <Col xl={3}>
                              <div>
                                <Label htmlFor="inputDOB" className="form-label mb-1">Loron Moris</Label>
                                <Flatpickr
                                  id="inputDOB"
                                  tabIndex={2}
                                  className="form-control rounded-0"
                                  options={{
                                    dateFormat: "d M, Y",
                                    defaultDate: generateDateWithPreviousYear(),
                                  }}
                                  onChange={([date]) => handleAction.onInputCommonDataChange('inputDOB', date)}
                                />
                              </div>
                            </Col>

                            <Col xl={3}>
                              <div>
                                <Label htmlFor="inputGender" className="form-label mb-1">Zenêru</Label>
                                <select tabIndex={3} className="form-select rounded-0"
                                        value={inputCommonData['inputGender'] || ''}
                                        onChange={(e) => handleAction.onInputCommonDataChange('inputGender', e.target.value)}>
                                  <option value="M">Male</option>
                                  <option value="F">Female</option>
                                </select>
                              </div>
                            </Col>

                            <Col xl={3}>
                              <div>
                                <Label htmlFor="inputAGE" className="form-label mb-1">Tinan</Label>
                                <Input type="text" className="form-control rounded-0 disabled" id="inputAGE"
                                       value={calculateAge(inputCommonData['inputDOB'] || generateDateWithPreviousYear())}
                                       disabled/>
                              </div>
                            </Col>

                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputFatherName" className="form-label mb-1">Aman nia
                                  Naran</Label>
                                <Input tabIndex={4} type="text" className="form-control rounded-0"
                                       value={actionOnInputPortugueseValue['inputFatherName'] || ''}
                                       id="inputFatherName" innerRef={(ref) => {
                                  inputRefs.current['inputFatherName'] = ref
                                }}
                                       onChange={(e) => actionOnInputPortugueseChange('inputFatherName', inputRefs.current['inputFatherName'], e.target.value)}
                                       onFocus={(e) => actionOnInputPortugueseFocused(inputRefs.current['inputFatherName'], e)}
                                />
                              </div>
                            </Col>

                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputMotherName" className="form-label mb-1">Inan Nia
                                  Naran</Label>
                                <Input tabIndex={5} type="text" className="form-control rounded-0"
                                       value={actionOnInputPortugueseValue['inputMotherName'] || ''}
                                       id="inputMotherName" innerRef={(ref) => {
                                  inputRefs.current['inputMotherName'] = ref
                                }}
                                       onChange={(e) => actionOnInputPortugueseChange('inputMotherName', inputRefs.current['inputMotherName'], e.target.value)}
                                       onFocus={(e) => actionOnInputPortugueseFocused(inputRefs.current['inputMotherName'], e)}/>
                              </div>
                            </Col>

                            <div className="border mt-3 mb-3 border-dashed"></div>

                            <Col xl={2}>
                              <div>
                                <Label htmlFor="inputBornInTimor" className="form-label mb-1">Born in
                                  Timor</Label>
                                <select tabIndex={6} className="form-select rounded-0" id="inputBornInTimor"
                                        onChange={(e) => handleAction.toggleIsBornInTimor(e)}>
                                  <option value={1}>Yes</option>
                                  <option value={0}>No</option>
                                </select>
                              </div>
                            </Col>

                            {!isBornInTimor ? (
                              <>
                                <Col xl={4}>
                                  <div>
                                    <Label className="form-label mb-1">Nasaun</Label>
                                    <Dropdown isOpen={!isBornInTimor && dropdownCountryCode}
                                              toggle={handleAction.toggleCountryCode}>
                                      <DropdownToggle tag="div" caret={false} type="text"
                                                      className="w-100 form-control rounded-0 flag-input">
                                        {inputCommonData['inputBornInOtherCountry'] || 'Select country'}
                                      </DropdownToggle>
                                      <DropdownMenu tag='ul'
                                                    className="list-unstyled w-100 dropdown-menu-list mb-0">
                                        <SimpleBar style={{maxHeight: "220px"}} className="px-3">
                                          {
                                            country.map((item, key) => (
                                              <DropdownItem as='li'
                                                            onClick={(e) => handleAction.onInputCommonDataChange('inputBornInOtherCountry', item.countryName)}
                                                            key={key}
                                                            className="dropdown-item d-flex ">
                                                <div className="flex-shrink-0 me-2">
                                                  <img src={item.flagImg} alt="country flag"
                                                       className="options-flagimg"
                                                       height="20"/>
                                                </div>
                                                <div className="flex-grow-1">
                                                  <div className="d-flex">
                                                    <div
                                                      className="country-name me-1">{item.countryName}</div>
                                                    <span
                                                      className="countrylist-codeno text-muted">{item.countryCode}</span>
                                                  </div>
                                                </div>
                                              </DropdownItem>
                                            ))
                                          }
                                        </SimpleBar>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>
                                </Col>

                                <Col xl={4}>
                                  <div>
                                    <Label htmlFor="inputBornInOtherCity"
                                           className="form-label mb-1">City</Label>
                                    <Input type="text" className="form-control rounded-0"
                                           value={actionOnInputPortugueseValue['inputBornInOtherCity'] || ''}
                                           id="inputBornInOtherCity" innerRef={(ref) => {
                                      inputRefs.current['inputBornInOtherCity'] = ref
                                    }}
                                           onChange={(e) => actionOnInputPortugueseChange('inputBornInOtherCity', inputRefs.current['inputBornInOtherCity'], e.target.value)}
                                           onFocus={(e) => actionOnInputPortugueseFocused(inputRefs.current['inputBornInOtherCity'], e)}
                                    />
                                  </div>
                                </Col>
                              </>
                            ) : (
                              <>
                                <Col xl={6}/>
                              </>
                            )}

                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputOriginDistrict" className="form-label mb-1">Municipiu</Label>
                                <Select id="inputOriginDistrict" className="rounded-0"
                                        options={listRegions['inputOriginDistrict_list']}
                                        value={listRegions['inputOriginDistrict_list']?.find(option => option.value === inputCommonData.inputOriginDistrict) || null}
                                        onChange={(e) => handleAction.onInputCommonDataChange('inputOriginDistrict', e.value, true)}
                                        onFocus={(e) => handleAction.onFocusInputRegion('inputOriginDistrict', 'district')}
                                        isDisabled={!isBornInTimor}
                                />
                              </div>
                            </Col>

                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputOriginSubDistrict" className="form-label mb-1">Posto
                                  Administrativu</Label>
                                <Select id="inputOriginSubDistrict" className="rounded-0"
                                        options={listRegions['inputOriginSubDistrict_list']}
                                        value={listRegions['inputOriginSubDistrict_list']?.find(option => option.value === inputCommonData.inputOriginSubDistrict) || null}
                                        onChange={(e) => handleAction.onInputCommonDataChange('inputOriginSubDistrict', e.value, true)}
                                        onFocus={(e) => handleAction.onFocusInputRegion('inputOriginSubDistrict', 'subdistrict')}
                                        isDisabled={!isBornInTimor}
                                />
                              </div>
                            </Col>

                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputOriginSucco" className="form-label mb-1">Succo</Label>
                                <Select id="inputOriginSucco" className="rounded-0"
                                        options={listRegions['inputOriginSucco_list']}
                                        value={listRegions['inputOriginSucco_list']?.find(option => option.value === inputCommonData.inputOriginSucco) || null}
                                        onChange={(e) => handleAction.onInputCommonDataChange('inputOriginSucco', e.value, true)}
                                        onFocus={(e) => handleAction.onFocusInputRegion('inputOriginSucco', 'succo')}
                                        isDisabled={!isBornInTimor}
                                />
                              </div>
                            </Col>

                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputOriginAldeia" className="form-label mb-1">Aldeia</Label>
                                <Select id="inputOriginAldeia" className="rounded-0"
                                        options={listRegions['inputOriginAldeia_list']}
                                        value={listRegions['inputOriginAldeia_list']?.find(option => option.value === inputCommonData.inputOriginAldeia) || null}
                                        onChange={(e) => handleAction.onInputCommonDataChange('inputOriginAldeia', e.value, true)}
                                        onFocus={(e) => handleAction.onFocusInputRegion('inputOriginAldeia', 'aldeia')}
                                        isDisabled={!isBornInTimor}
                                />
                              </div>
                            </Col>

                            <Col xl={12}>
                              <div>
                                <Label htmlFor="inputOriginAddress" className="form-label mb-1">Address</Label>
                                <Input type="text" className="form-control rounded-0"
                                       value={actionOnInputPortugueseValue['inputOriginAddress'] || ''}
                                       id="inputOriginAddress" innerRef={(ref) => {
                                  inputRefs.current['inputOriginAddress'] = ref
                                }}
                                       onChange={(e) => actionOnInputPortugueseChange('inputOriginAddress', inputRefs.current['inputOriginAddress'], e.target.value)}
                                       onFocus={(e) => actionOnInputPortugueseFocused(inputRefs.current['inputOriginAddress'], e)}
                                       disabled={!isBornInTimor}/>
                              </div>
                            </Col>

                            <Col xl={6}>
                              <Row className="gy-1">
                                <Col xl={12}>
                                  <h5 className="fs-14 mb-2 text-muted">Dokumentu</h5>
                                  <div>
                                    <Label htmlFor="inputTypeDocument" className="form-label mb-1">Type of
                                      Identity
                                      Document</Label>
                                    <select id="inputTypeDocument" className="form-select rounded-0"
                                            value={inputCommonData['inputTypeDocument'] || ''}
                                            onChange={(e) => handleAction.onInputCommonDataChange('inputTypeDocument', e.target.value)}>
                                      <option>RDTL</option>
                                      <option>Baptismal Certificate</option>
                                      <option>Passport</option>
                                      <option>National Identity Card</option>
                                      <option>Religious Certificate</option>
                                      <option>Other</option>
                                    </select>
                                  </div>
                                </Col>

                                <Col xl={12}>
                                  <div>
                                    <Label htmlFor="inputNameDocument" className="form-label mb-1">Naran</Label>
                                    <Input type="text" className="form-control rounded-0"
                                           value={actionOnInputPortugueseValue['inputNameDocument'] || ''}
                                           id="inputNameDocument" innerRef={(ref) => {
                                      inputRefs.current['inputNameDocument'] = ref
                                    }}
                                           onChange={(e) => actionOnInputPortugueseChange('inputNameDocument', inputRefs.current['inputNameDocument'], e.target.value)}
                                           onFocus={(e) => actionOnInputPortugueseFocused(inputRefs.current['inputNameDocument'], e)}
                                    />
                                  </div>
                                </Col>

                                <Col xl={12}>
                                  <div>
                                    <Label htmlFor="inputNoDocument" className="form-label mb-1">Numeru
                                      Dokumentu</Label>
                                    <Input type="text" className="form-control rounded-0" id="inputNoDocument"
                                           onChange={(e) => handleAction.onInputCommonDataChange('inputNoDocument', e.target.value)}
                                    />
                                  </div>
                                </Col>

                                <Col xl={12}>
                                  <div>
                                    <Label htmlFor="inputIssuedInDocument" className="form-label mb-1">Emitidu
                                      iha</Label>
                                    <Input type="text" className="form-control rounded-0"
                                           id="inputIssuedInDocument"
                                           onChange={(e) => handleAction.onInputCommonDataChange('inputIssuedInDocument', e.target.value)}
                                    />
                                  </div>
                                </Col>

                                <Col xl={12}>
                                  <div>
                                    <Label htmlFor="inputIssuedAtDocument" className="form-label mb-1">Emitidu
                                      iha</Label>
                                    <Flatpickr
                                      className="form-control rounded-0"
                                      options={{
                                        dateFormat: "d M, Y",
                                      }}
                                      onChange={([date]) => handleAction.onInputCommonDataChange('inputIssuedAtDocument', date)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col xl={3}>
                              <Row className="gy-1">
                                <Col xl={12}>
                                  <h5 className="fs-14 mb-2 text-muted">Data Digital</h5>
                                  <div>
                                    <Label htmlFor="inputFileDocument"
                                           className="form-label mb-1">Dokumentu</Label>
                                    <FilePond
                                      id="inputFileDocument"
                                      files={fileDocuments}
                                      onupdatefiles={setFileDocuments}
                                      allowMultiple={true}
                                      maxFiles={3}
                                      maxFileSize="2MB"
                                      name="document"
                                      className="filepond filepond-input-multiple"
                                      acceptedFileTypes={['application/pdf']}
                                      labelIdle="Select a PDF file with a size of 2MB"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>

                            <div className="d-flex align-items-start gap-3 mt-4">
                              <button
                                type="button"
                                className="btn btn-link text-decoration-none btn-label previestab"
                                onClick={() => {
                                  toggleStep(stateSteps['activeStep'] - 1, 0);
                                }}
                              >
                                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                Back to Base Information
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary rounded-0 ms-auto "
                              >
                                Submit
                              </button>
                            </div>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Modal
          isOpen={showModalNotification}
          toggle={() => {
            setShowModalNotification(!showModalNotification)
            }}
            centered
            backdrop={'static'}
        >
          <ModalHeader className="modal-title"/>

          <ModalBody className="text-center p-5 rounded-0">
            <div className="mt-4">
              {registerOnlineStore.isProcessDone && registerOnlineStore.resultset?.dataElectorRegistered !== null ? (
                  <>
                    <div className="avatar-lg mx-auto mt-2">
                      <div className="avatar-title bg-light text-danger display-3 rounded-circle">
                        <i className="ri-checkbox-circle-fill"></i>
                      </div>
                    </div>
                    <div className="mt-4 pt-2">
                      <h5>Process Registration Successfully</h5>
                      <div className="mt-4">
                        <Link className="btn btn-primary rounded-0" to={GUEST_REGISTER_STATUS}>Go to Check Registration
                          Status</Link>
                      </div>
                    </div>
                  </>
              ) : (
                  <>
                    <div className="avatar-lg mx-auto mt-2">
                      <div className="avatar-title bg-light text-danger display-3 rounded-circle">
                        <i className="ri-close-circle-fill"></i>
                      </div>
                    </div>
                    <div className="mt-4 pt-2">
                      <h4>Somethings wrong.</h4>
                      <div className="mt-4">
                        <a className="btn btn-primary rounded-0" href={GUEST_REGISTER}>Reload</a>
                      </div>
                    </div>
                  </>
              )}
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={showModalAlreadyRegistered}
          toggle={() => {
            setShowModalAlreadyRegistered(!showModalAlreadyRegistered)
          }}
          centered
          backdrop={'static'}
        >
          <ModalHeader className="modal-title"/>

          <ModalBody className="text-center p-5 rounded-0">
            <div className="mt-4">
              <div className="avatar-lg mx-auto mt-2">
                <div className="avatar-title bg-light text-danger display-3 rounded-circle">
                  <i className="ri-checkbox-circle-fill"></i>
                </div>
              </div>
              <div className="mt-4 pt-2">
                <h5>You already registered</h5>
                <div className="mt-4">
                  <Link className="btn btn-primary rounded-0" to={GUEST_REGISTER_STATUS}>Go to Check Registration
                    Status</Link>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
  )
}

export default withRouter(RegisterOnline);