import withRouter from "../../Components/Common/withRouter";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import React from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {GUEST_REGISTER} from "../../config/url";

const RegisterTimeline = () => {
  const statusRegister = 1;
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <CustomBreadcrumb title="Registration Report" pageTitle="Pages"/>
          <Row>
            {statusRegister === 0 ? (
              <>
                <CardBody className="card-body">
                  <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={6}>
                      <Card className="mt-4 shadow-none">
                        <CardBody className="p-4 text-center ">
                          <div className="avatar-lg mx-auto mt-2">
                            <div className="avatar-title bg-light text-danger display-3 rounded-circle">
                              <i className="ri-close-circle-fill"></i>
                            </div>
                          </div>
                          <div className="mt-4 pt-2">
                            <h4>You have not submitted a registration yet.</h4>
                            <div className="mt-4">
                              <Link className="btn btn-primary rounded-0" to={GUEST_REGISTER}>Go to Online Registration</Link>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </>
            ) : (
              <>
                <Col xl={12}>
                  <div>
                    <div className="timeline">
                      <div className="timeline-item left">
                        <i className="icon ri-stack-line"></i>
                        <div className="date">10 May 2024</div>
                        <div className="content rounded-0 shadow-sm p-3">
                          <div className="d-flex mb-0">
                            <div className="flex-grow-1 mb-0">
                              <h5 className="fs-15">Proposed new registration</h5>
                              <p className="text-muted mb-0"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item left">
                        <i className="icon ri-stack-line"></i>
                        <div className="date">10 May 2024</div>
                        <div className="content rounded-0 shadow-sm p-3">
                          <h5>In Process Verification <span
                            className="badge bg-success-subtle text-success fs-10 align-middle ms-1">Completed</span></h5>

                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default withRouter(RegisterTimeline);