import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import Cookies from 'js-cookie';
import {APIClient} from "../../../helpers/api_helper";
import {BASE_API_URL, SESSION_NAME} from "../../../config/constants";
import {GUEST_REGISTER_PROFILE} from "../../../config/url";

const api = new APIClient();

export const processPostSignOut = createAsyncThunk("processPostSignOut", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = api.create(`${BASE_API_URL}auth/sign-out`)
    const result = await response;

    if(result){
      return 'ok'
    }
  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});