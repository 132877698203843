import {toast} from "react-toastify";

export const getSessionStorage = () => {
  return {
    "fullname": "",
    "email": "",
    "username": "",
    "role": "GU",
    "phone_number": "",
    "itx": 0,
    "avatar": ""
  }
}

export const generateDateWithPreviousYear = (yearAgo=17) => {
  const currentDate = new Date();

  return new Date(currentDate.getFullYear() - yearAgo, currentDate.getMonth(), currentDate.getDate());
}

export const calculateAge = (birthDate) => {
  if (!birthDate) return null;

  const currentDate = new Date();
  const birthYear = birthDate.getFullYear();
  const birthMonth = birthDate.getMonth();
  const birthDay = birthDate.getDate();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  let age = currentYear - birthYear;

  if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDay < birthDay)
  ) {
    age--;
  }

  if (age >= 17) {
    return age
  } else {
    return 0
  }
};

export const generateFlatpickrFormat = (dateString) => {
  const date = new Date(dateString);
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day} ${monthNames[monthIndex]} ${year}`;
}

export const generateRandomNumber = (length=7) => {
  let result = '';
  const characters = '0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const generateImageStorage = (imageId) => {
  return `${process.env.REACT_APP_API_URL}storages/avatar?id=${imageId}`
}

export const generateToastifyError = (message) => {
  toast(message, { position: "top-right", hideProgressBar: true, className: 'rounded-0 bg-danger text-white' });
}