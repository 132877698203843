import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import {APIClient} from "../../../helpers/api_helper";
import {BASE_API_URL} from "../../../config/constants";

const api = new APIClient();

export const processPostRegister = createAsyncThunk("processPostRegister", async (data, { rejectWithValue, dispatch }) => {
  try {
    const {bindData, routerData} = data;
    const response = api.create(`${BASE_API_URL}auth/register`, JSON.stringify(bindData))
    const result = await response;

    if(result){
      return 'ok'
    }
  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});