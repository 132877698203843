import React from "react";

import SignIn from "../pages/authentication/SignIn";
import SignOut from "../pages/authentication/SignOut";
import Register from "../pages/register/Register";
import Starter from "../pages/starter/Starter";
import RegisterProfile from "../pages/register-profile/RegisterProfile";
import RegisterOnline from "../pages/register-online/RegisterOnline";
import MyApplication from "../pages/my-application/MyApplication";
import {
  AUTHENTICATION_REGISTER,
  AUTHENTICATION_SIGNIN,
  AUTHENTICATION_SIGNOUT, GUEST_MY_APPLICATION,
  GUEST_REGISTER, GUEST_REGISTER_ACTUALIZATION,
  GUEST_REGISTER_PROFILE,
  GUEST_REGISTER_REPORT,
  GUEST_REGISTER_STATUS,
  NOGUEST_DASHBOARD
} from "../config/url";
import RegisterTimeline from "../pages/register-timeline/RegisterTimeline";
import RegisterOnlineActualization from "../pages/register-online-actualization/RegisterOnlineActualization";

const publicRoute = [
  {path: AUTHENTICATION_SIGNIN, component: <SignIn />},
  {path: AUTHENTICATION_REGISTER, component: <Register />},
  {path: '/', exact: true, component: <Starter />}
]
const privateRoute = [
  {path: NOGUEST_DASHBOARD, component: <div>dashboard</div>},
  {path: AUTHENTICATION_SIGNOUT, component: <SignOut />},
  {path: GUEST_REGISTER_PROFILE, component: <RegisterProfile />},
  {path: GUEST_REGISTER, component: <RegisterOnline />},
  {path: GUEST_REGISTER_ACTUALIZATION, component: <RegisterOnlineActualization />},
  {path: GUEST_REGISTER_STATUS, component: <RegisterTimeline />},
  {path: GUEST_MY_APPLICATION, component: <MyApplication />},
  {path: GUEST_REGISTER_REPORT, component: <>Report Registration</>},
  {path: '*', component: <div>dashboard</div>},
]

export { publicRoute, privateRoute };