import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import {APIClient} from "../../../helpers/api_helper";
import {BASE_API_URL} from "../../../config/constants";

const api = new APIClient();

export const processChangeDataRegisterProfile = createAsyncThunk("processChangeDataRegisterProfile", async (data, { rejectWithValue, dispatch }) => {
  try {
    const {bindData, routerData} = data;
    const {fileAvatar, textValues} = bindData;

    let isReturnSuccess = false;
    if(fileAvatar === undefined || fileAvatar === null){
      const responseChangeDataRegisterProfile = api.create(`${BASE_API_URL}auth/register/change`, textValues);

      isReturnSuccess = responseChangeDataRegisterProfile.is_success;
    } else {
      const formData = new FormData();
      formData.append("avatar", fileAvatar);

      const [responseChangeAvatar, responseChangeDataRegisterProfile] = await Promise.all([
        api.createFile(`${BASE_API_URL}storages/avatar/upload`, formData),
        api.create(`${BASE_API_URL}auth/register/change`, textValues)
      ]);

      isReturnSuccess = responseChangeAvatar.is_success && responseChangeDataRegisterProfile.is_success;
    }

    if(isReturnSuccess){
      return 'ok'
    }
  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});

export const processChangeAvatarProfile = createAsyncThunk("processChangeAvatarProfile", async (data, { rejectWithValue, dispatch }) => {
  try {
    const {bindData, routerData} = data;
    const {fileAvatar} = bindData;

    const formData = new FormData();
    formData.append("avatar", fileAvatar);

    const result = api.createFile(`${BASE_API_URL}storages/avatar/upload`, formData);

    if(result){
      return {
        isChangeAvatarSuccess: true,
      };
    }
  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});