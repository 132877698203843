import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import withRouter from '../Components/Common/withRouter';

//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Layout = (props) => {
    const mainContainerRef = useRef(null);
    const [headerClass, setHeaderClass] = useState("");
    const [isShowButtonPortugueseChars, setIsShowButtonPortugueseChars] = useState(false);
    const [inputPortugueseChange, setInputPortugueseChange] = useState({
        inputProfileFullname: props.fromMe?.fullname
    });
    const [activeRef, setActiveRef] = useState(null);

    useEffect(() => {
        document.documentElement.setAttribute('data-sidebar', 'dark');
        window.addEventListener("scroll", scrollNavigation, true);
    });

    useEffect(() => {
        const handleOnClickMainContainer = (event) => {
            if (event.target.closest('header') === null && event.target.closest('input') === null && activeRef === null) {
                setActiveRef(null);
            }
        };
        document.addEventListener('click', handleOnClickMainContainer);

        return () => {
            document.removeEventListener('click', handleOnClickMainContainer);
        };
    }, []);


    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }

    const handle = {
        onClickPortugueseButton: (char) => {
            if(activeRef !== null){
                activeRef.focus();

                const getActiveId = activeRef.id;
                setInputPortugueseChange(prevState => ({
                    ...prevState,
                    [getActiveId]: (inputPortugueseChange[getActiveId] || '') + char
                }))
            }
        },
        onClickButtonPortugueseChars: () => {
            setIsShowButtonPortugueseChars(!isShowButtonPortugueseChars);
            if(activeRef !== null){
                activeRef.focus()
            }
        },
        onInputPortugueseChange: (inputId, inputRef, value) => {
            setActiveRef(inputRef);
            setInputPortugueseChange(prevState => ({
                ...prevState,
                [inputId]: value
            }))
        },
        onInputPortugueseFocused: (inputRef) => {
            setActiveRef(inputRef);
        },
    }

    window.onscroll = function () {
        scrollFunction();
    };

    const scrollFunction = () => {
        const element = document.getElementById("back-to-top");
        if (element) {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        }
    };

    const propsToChildren = {
        fromMe: props.fromMe,
        actionOnInputPortugueseChange: handle.onInputPortugueseChange,
        actionOnInputPortugueseValue: inputPortugueseChange,
        actionOnInputPortugueseFocused: handle.onInputPortugueseFocused,
    }

    const toTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    return (
      <>
          <div id="layout-wrapper">
              <Header
                headerClass="topbar-shadow"
                layoutModeType="vertical"
                fromMe={props.fromMe}
                actionIsShowButtonPortugueseChars={isShowButtonPortugueseChars}
                actionOnClickButtonPortugueseChars={handle.onClickButtonPortugueseChars}
                actionOnPortugueseButton={handle.onClickPortugueseButton}
              />
              <Sidebar
                layoutType="vertical"
              />
              <div ref={mainContainerRef} className="main-content">
                  {React.cloneElement(props.children, propsToChildren)}
                  <Footer/>
              </div>
          </div>
          <ToastContainer/>

          <button onClick={() => toTop()} className="btn btn-danger btn-icon landing-back-top" id="back-to-top">
              <i className="ri-arrow-up-line"></i>
          </button>
      </>

    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);