import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {Card, CardBody, Col, Container, Input, Label, Row, Button, Alert, Spinner} from 'reactstrap';
import withRouter from "../../Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";

import logostae from "../../assets/images/logo-stae.png";
import {APP_NAME, CORP_NAME, SHORT_APP_NAME, SHORT_CORP_NAME} from "../../config/constants";
import {processPostSignIn} from "../../states/authentication/signin/thunk";
import {AUTHENTICATION_REGISTER, AUTHENTICATION_SIGNIN} from "../../config/url";
import FooterCopyright from "./FooterCopyright";


const SignIn = (props) => {
  const dispatch = useDispatch();
  const signInStore = useSelector((state) => state.SignIn);

  const [valueEmail, setValueEmail] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);

  const validationInputSignIn = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: valueEmail,
      password: valuePassword,
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      const data = {
        bindData: values,
        routerData: props.router
      }
      dispatch(processPostSignIn(data));
    }
  })

  const handleAction = {
    onSubmit: (e) => {
      e.preventDefault();
      validationInputSignIn.handleSubmit();

      return false;
    }
  }

  return (
      <>
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-0 mb-0 rounded-0 shadow-lg shadow shadow-sm border-primary"
                      style={{borderTop: "3px solid"}}>
                  <CardBody className="p-3">
                    <div className="text-center mt-2 text-primary">
                      <div>
                        <Link to={AUTHENTICATION_SIGNIN} className="d-inline-block auth-logo">
                          <img src={logostae} alt="" height={110}/>
                        </Link>
                      </div>
                      <p className="mt-3 mb-0 fs-15 fw-medium text-uppercase">{APP_NAME}</p>
                    </div>
                    <div className="border mt-3 mb-3 border-dashed"></div>
                    <div className="text-center mt-0">
                      <h4 className="text-primary">TAMA</h4>
                    </div>
                    <div className="p-2 mt-0">
                      {signInStore.isError ? (
                          <Alert color="danger" className="rounded-0 py-2">{signInStore.errorset}</Alert>) : null}
                      <form autoComplete="off" onSubmit={(e) => handleAction.onSubmit(e)}>
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">Enderesu Eletroniku</Label>
                          <Input tabIndex="1" type="email" className="form-control rounded-0" id="email"
                                 placeholder="Enter Enderesu Eletroniku" autoFocus
                                 onChange={(e) => setValueEmail(e.target.value)}
                                 invalid={validationInputSignIn.touched.email && validationInputSignIn.errors.email}/>
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/#" className="text-muted">Haluha Liafuan Xave</Link>
                          </div>
                          <Label className="form-label" htmlFor="password-input">Liafuan Xave</Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input tabIndex="2" type={passwordShow ? "text" : "password"}
                                   className="form-control pe-5 password-input rounded-0" placeholder="Enter Liafuan Xave"
                                   id="password-input" onChange={(e) => setValuePassword(e.target.value)}
                                   invalid={validationInputSignIn.touched.password && validationInputSignIn.errors.password}/>
                            <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i
                                className="ri-eye-fill align-middle"></i></button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check"/>
                          <Label className="form-check-label" htmlFor="auth-remember-check">Hau</Label>
                        </div>

                        <div className="mt-4">
                          <Button color="primary" className="btn btn-primary w-100 rounded-0" type="submit">
                            {signInStore.isLoading &&
                                <Spinner size="sm" className='me-2'> Loading... </Spinner>}
                            Tama
                          </Button>
                        </div>
                      </form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-2 text-center">
                  <p className="mb-0">Hakarak rejistu ?
                    <Link to={AUTHENTICATION_REGISTER}
                          className="fw-semibold text-primary"> Rejistu </Link>
                  </p>
                  <p className="mb-0 mt-2">
                    <Link to="/"
                          className="fw-semibold text-primary text-decoration-underline"> Filla ba pajina </Link>
                  </p>
                </div>
                <FooterCopyright />

              </Col>
            </Row>
          </Container>
        </div>
      </>
  );
};

export default withRouter(SignIn);