import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import Cookies from 'js-cookie';
import {APIClient} from "../../../helpers/api_helper";
import {BASE_API_URL, SESSION_NAME} from "../../../config/constants";
import {GUEST_REGISTER_PROFILE} from "../../../config/url";

const api = new APIClient();

export const processPostSignIn = createAsyncThunk("processPostSignIn", async (data, { rejectWithValue, dispatch }) => {
  try {
    const {bindData, routerData} = data;
    const response = api.create(`${BASE_API_URL}auth/sign-in`, JSON.stringify(bindData))
    const result = await response;

    if(result){
      const {data} = result;
      const expirationTime = new Date();
      expirationTime.setTime(expirationTime.getTime() + data.expiresIn);

      localStorage.setItem("I18N_LANGUAGE", "tt");
      Cookies.set(SESSION_NAME, data.token, {expires: expirationTime});
      
      window.location.replace(GUEST_REGISTER_PROFILE);
    }
  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});