export const BASE_PREFIX_APPS = '/els'
export const BASE_PREFIX_AUTHENTICATION = '/authentication'
export const AUTHENTICATION_SIGNIN = `${BASE_PREFIX_AUTHENTICATION}/sign-in`
export const AUTHENTICATION_SIGNOUT = `${BASE_PREFIX_AUTHENTICATION}/sign-out`
export const AUTHENTICATION_REGISTER = `${BASE_PREFIX_AUTHENTICATION}/register`

export const GUEST_MY_APPLICATION = `${BASE_PREFIX_APPS}/my-application`
export const GUEST_REGISTER_PROFILE = `${BASE_PREFIX_APPS}/register/profile`
export const GUEST_REGISTER = `${BASE_PREFIX_APPS}/register`
export const GUEST_REGISTER_ACTUALIZATION = `${BASE_PREFIX_APPS}/register/actualization`
export const GUEST_REGISTER_STATUS = `${BASE_PREFIX_APPS}/register/status`
export const GUEST_REGISTER_REPORT = `${BASE_PREFIX_APPS}/register/report`

export const NOGUEST_DASHBOARD = `${BASE_PREFIX_APPS}/dashboard`
