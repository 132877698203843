import React from 'react';
import { Routes, Route } from "react-router-dom";
import ScreenLayout from "../Layouts/index";
import { publicRoute, privateRoute } from "./router";
import { AuthenticatedRoute } from './AuthenticatedRoute';

const Index = () => {
    return (
        <React.Fragment>
            <Routes>
                <Route>
                    {publicRoute.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <>
                                    {route.component}
                                </>
                            }
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Route>

                <Route>
                    {privateRoute.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <AuthenticatedRoute>
                                    <ScreenLayout>
                                      {route.component}
                                    </ScreenLayout>
                                </AuthenticatedRoute>}
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export default Index;