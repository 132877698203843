import React from 'react';
import './assets/scss/themes.scss';
import './assets/css/custom.css';
import Route from './Routes';

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
