import flagus from "../assets/images/flags/us.svg"
import flagid from "../assets/images/flags/id.svg"
import flagpt from "../assets/images/flags/pt.svg"
import flagtt from "../assets/images/flags/tl.svg"


const languages = {
    en: {
        label: "English",
        flag: flagus,
    },
    id: {
        label: "Bahasa Indonesia",
        flag: flagid,
    },
    pt: {
        label: "Português",
        flag: flagpt,
    },
    tt: {
        label: "Tetun Timor",
        flag: flagtt,
    },
}

export default languages