import React, {useEffect, useRef, useState} from 'react';
import withRouter from "../../Components/Common/withRouter";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane, UncontrolledAlert
} from 'reactstrap';
import classnames from "classnames";
import {generateImageStorage, generateRandomNumber, getSessionStorage} from "../../helpers/general_helper";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";

import {useFormik} from "formik";
import * as Yup from "yup";
import 'react-phone-number-input/style.css'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import {useDispatch, useSelector} from "react-redux";
import {processChangeDataRegisterProfile, processChangeAvatarProfile} from "../../states/authentication/register-profile/thunk";

const RegisterProfile = (props) => {
  const dispatch = useDispatch();
  const registerProfileStore = useSelector((state) => state.RegisterProfile);

  const {actionOnInputPortugueseFocused, actionOnInputPortugueseChange, actionOnInputPortugueseValue} = props;
  const fromMe = props.fromMe;
  const inputRefs = useRef({});
  const [activeTab, setActiveTab] = useState("1");
  const [inputCommonData, setInputCommonData] = useState({
    inputProfileEmail: fromMe?.email,
    inputProfilePhoneNumber: fromMe?.phone_number,
  });
  const [valueFullname, setValueFullname] = useState(fromMe?.fullname);
  const [valueEMail, setValueEMail] = useState(fromMe?.email);
  const [valuePhoneNumber, setValuePhoneNumber] = useState(fromMe?.phone_number);
  const [valuePhoneCode, setValuePhoneCode] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const [valuePasswordConfirmation, setValuePasswordConfirmation] = useState("");
  const [pathAvatar, setPathAvatar] = useState(generateImageStorage(fromMe?.avatar));
  const [fileAvatar, setFileAvatar] = useState("");
  const [keyElement, setKeyElement] = useState({});
  const [isShowMessageBeforeSubmit, setIsShowMessageBeforeSubmit] = useState(false);
  const [messageBeforeSubmit, setMessageBeforeSubmit] = useState("");

  useEffect(() => {
    const getCountryFromPhonNumber = parsePhoneNumber(`+${inputCommonData['inputProfilePhoneNumber']}`);

    if (getCountryFromPhonNumber !== undefined){
      setInputCommonData(prevState => ({
        ...prevState,
        inputProfilePhoneNumber: getCountryFromPhonNumber.number,
        inputProfilePhoneCode: getCountryFromPhonNumber.country,
      }))
    }
  }, []);

  const validationInputChangeDataRegister = useFormik({
    enableReinitialize: true,

    initialValues: {
      fullname: actionOnInputPortugueseValue['inputProfileFullname'],
      email: inputCommonData['inputProfileEmail'],
      phone_number: inputCommonData['inputProfilePhoneNumber'],
      password: inputCommonData['inputProfilePassword'],
      passwordConfirmation: inputCommonData['inputProfilePasswordConfirmation'],
    },
    validationSchema: Yup.object({
      fullname: Yup.string(),
      email: Yup.string().email(),
      phone_number: Yup.string(),
      password: Yup.string().min(8, 'Password must be at least 8 characters'),
      passwordConfirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
    }),
    onSubmit: (values) => {
      if (values.fullname === actionOnInputPortugueseValue['inputProfileFullname'] &&
          values.email === fromMe.email &&
          values.phoneNumber === fromMe.phoneNumber &&
          values.password === '' && values.passwordConfirmation === '' && fileAvatar === '') {
        setIsShowMessageBeforeSubmit(!isShowMessageBeforeSubmit);
        setMessageBeforeSubmit("The form data does not reflect any changes made by the user.");
      } else {
        const passBindData = {
          textValues: values
        }

        if(fileAvatar !== ''){
          passBindData.fileAvatar = fileAvatar.target.files[0];
        }

        const data = {
          bindData: passBindData,
          routerData: props.router
        }

        dispatch(processChangeDataRegisterProfile(data))
      }
    }
  });

  const handleAction = {
    onChangeAvatar: (e) => {
      const getTempFile = e.target.files[0];
      if(getTempFile){
        const data = {
          bindData: {fileAvatar: getTempFile},
          routerData: props.router
        }
        dispatch(processChangeAvatarProfile(data))
      }
    },
    onSubmit: (e) => {
      e.preventDefault();

      validationInputChangeDataRegister.handleSubmit(e);
    },
    onInputCommonDataChange: (inputId, value, isInputRegion= false) => {

      setInputCommonData(prevState => ({
        ...prevState,
        [inputId]: value
      }));
    }
  }

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if(registerProfileStore.isProcessDone && registerProfileStore.resultset?.isChangeAvatarSuccess){
    window.location.reload();
  }

  return (
      <>
        <div className="page-content">
          <Container fluid>
            <CustomBreadcrumb title="Profile" pageTitle="Pages"/>
            <Row>
              <Col xl={9}>
                <Card className="mt-1 rounded-0">
                  <CardHeader>
                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                         role="tablist">
                      <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === "1"})}
                            onClick={() => {
                              tabChange("1");
                            }}>
                          <i className="fas fa-home"></i>
                          Detallu pessoal
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink to="#"
                                 className={classnames({active: activeTab === "2"})}
                                 onClick={() => {
                                   tabChange("2");
                                 }}
                                 type="button">
                          <i className="far fa-user"></i>
                          Change Password
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>
                  <CardBody className="p-4">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <form autoComplete="off" onSubmit={(e) => handleAction.onSubmit(e)}>
                          <Row>
                            <div className="text-center mb-4">
                              <div key={generateRandomNumber()} className="profile-user position-relative d-inline-block mx-auto mb-4">
                                <img src={pathAvatar}
                                     className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                     alt="user-avatar-profile" />
                                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                  <Input id="profile-img-file-input" type="file"
                                         className="profile-img-file-input" onChange={(e) => handleAction.onChangeAvatar(e)} />
                                  <Label htmlFor="profile-img-file-input"
                                         className="profile-photo-edit avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                  </Label>
                                </div>
                              </div>
                              <h5 className="fs-16 mb-1">{fromMe?.fullname}</h5>
                              <p className="text-muted mb-0">Super User</p>
                            </div>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              {isShowMessageBeforeSubmit &&
                              <UncontrolledAlert color="warning" className="material-shadow rounded-0" toggle={() => setIsShowMessageBeforeSubmit(!isShowMessageBeforeSubmit)}>
                                {messageBeforeSubmit}
                              </UncontrolledAlert>
                              }
                              <div className="mb-3">
                                <Label htmlFor="fullname" className="form-label">Naran Kompletu</Label>
                                <Input type="text" className="form-control rounded-0" id="inputProfileFullname"
                                       placeholder="Enter your fullname" value={actionOnInputPortugueseValue['inputProfileFullname'] || ''}
                                       innerRef={(ref) => {inputRefs.current['inputProfileFullname'] = ref}}
                                       onChange={(e) => actionOnInputPortugueseChange('inputProfileFullname', inputRefs.current['inputProfileFullname'], e.target.value)}
                                       onFocus={(e) => actionOnInputPortugueseFocused(inputRefs.current['inputProfileFullname'], e)}
                                       invalid={validationInputChangeDataRegister.touched.fullname && validationInputChangeDataRegister.errors.fullname}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label htmlFor="phone_number" className="form-label">Numeru Telefone</Label>

                                <PhoneInput
                                  id="inputProfilePhoneNumber"
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry={inputCommonData['inputProfilePhoneCode']}
                                  onChange={(e) => handleAction.onInputCommonDataChange('inputProfilePhoneNumber', e)}
                                  value={inputCommonData['inputProfilePhoneNumber']}
                                  className="form-control rounded-0"
                                  tabIndex={3}
                                    invalid={validationInputChangeDataRegister.touched.phone_number && validationInputChangeDataRegister.errors.phone_number}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label htmlFor="emailInput" className="form-label">Enderesu Eletroniku</Label>
                                <Input type="email" className="form-control rounded-0" id="inputProfileEmail"
                                       placeholder="Enter your email"
                                       value={inputCommonData['inputProfileEmail']}
                                       onChange={(e) => handleAction.onInputCommonDataChange('inputProfileEmail', e.target.value)}
                                       invalid={validationInputChangeDataRegister.touched.email && validationInputChangeDataRegister.errors.email}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="text-start mt-2">
                                <Button color="primary" type="submit" className="btn btn-primary rounded-0">Save</Button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row className="g-2">
                          <Col lg={12} xl={12}>
                            <div>
                              <Label htmlFor="password" className="form-label">Old Xave Foun*</Label>
                              <Input type="password" className="form-control rounded-0"
                                     id="inputProfilePassword" placeholder="Enter Xave Foun" value={inputCommonData['inputProfileOldPassword']}
                                     onChange={(e) => handleAction.onInputCommonDataChange('inputProfileOldPassword', e.target.value)}
                                     invalid={validationInputChangeDataRegister.touched.password && validationInputChangeDataRegister.errors.password}
                              />
                            </div>
                          </Col>

                          <Col lg={12} xl={12}>
                            <div>
                              <Label htmlFor="password" className="form-label">Xave Foun*</Label>
                              <Input type="password" className="form-control rounded-0"
                                     id="inputProfilePassword" placeholder="Enter Xave Foun" value={inputCommonData['inputProfileNewPassword']}
                                     onChange={(e) => handleAction.onInputCommonDataChange('inputProfileNewPassword', e.target.value)}
                                     invalid={validationInputChangeDataRegister.touched.password && validationInputChangeDataRegister.errors.password}
                              />
                            </div>
                          </Col>

                          <Col lg={12} xl={12}>
                            <div>
                              <Label htmlFor="passwordConfirmation" className="form-label">Konfima Xave foun*</Label>
                              <Input type="password" className="form-control rounded-0"
                                     id="inputProfilePasswordConfirmation"
                                     placeholder="Konfima Xave Foun"
                                     value={inputCommonData['inputProfilePasswordConfirmation']}
                                     onChange={(e) => handleAction.onInputCommonDataChange('inputProfileNewPasswordConfirmation', e.target.value)}
                                     invalid={validationInputChangeDataRegister.touched.passwordConfirmation && validationInputChangeDataRegister.errors.passwordConfirmation}
                              />
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="text-start mt-2">
                              <Button color="primary" type="submit" className="btn btn-primary rounded-0">Save</Button>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
  );
};

export default withRouter(RegisterProfile);