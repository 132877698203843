import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {Button, Dropdown, DropdownMenu, DropdownToggle, Form} from 'reactstrap';

//import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";
import logo from "../assets/images/logo-stae.png";

//import Components
import SearchOption from '../Components/Common/SearchOption';
import LanguageDropdown from '../Components/Common/LanguageDropdown';
import NotificationDropdown from '../Components/Common/NotificationDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import {PORTUGUESES_CHARACTERS} from "../config/constants";

const Header = ({ headerClass, fromMe, actionIsShowButtonPortugueseChars, actionOnClickButtonPortugueseChars, actionOnPortugueseButton }) => {
    const sidebarVisibilitytype = "show";

    const [isCapslockOn, setIsCapslockOn] = useState(false);

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle('open');

        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }

        //For collapse vertical and semibox menu
        if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }


        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };

    const getCapslockPress = (e) => {
        if(e.code === "CapsLock"){
            let isCapsLockOn = e.getModifierState("CapsLock");
            setIsCapslockOn(isCapsLockOn);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', getCapslockPress);
        document.addEventListener('keyup', getCapslockPress);
    }, []);

    return (
        <>
            <header id="page-topbar">
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex justify-content-center align-items-center">

                            <button
                                type="button"
                                className="btn btn-sm header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon"
                                onClick={actionOnClickButtonPortugueseChars}>
                                <i className="ri-keyboard-box-fill fs-22 m"/>
                            </button>

                            {actionIsShowButtonPortugueseChars && (
                                <>
                                    {PORTUGUESES_CHARACTERS.map((char, index) => (
                                        <Button size="sm" type="button" key={index} color="primary" outline
                                                className="waves-effect waves-light material-shadow-none my-1 mx-1 rounded-0"
                                                onClick={() => actionOnPortugueseButton(isCapslockOn ? char.toUpperCase() : char.toLowerCase())}>
                                            {isCapslockOn ? char.toUpperCase() : char.toLowerCase()}
                                        </Button>
                                    ))}
                                </>
                            )}

                            {/*<SearchOption />*/}
                        </div>

                        <div className="d-flex align-items-center">

                            <LanguageDropdown/>
                            <NotificationDropdown/>
                            <ProfileDropdown fromMe={fromMe}/>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;