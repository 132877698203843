import React, {useEffect, useState} from "react";
import {Navigate, Route, useLocation} from "react-router-dom";
import {getLoggedinUser, setAuthorization} from "../helpers/api_helper";
import {useDispatch, useSelector} from "react-redux";

import {AUTHENTICATION_SIGNIN} from "../config/url";
import {processPostAuthMe} from "../states/authentication/profile/thunk";
import Cookies from "js-cookie";
import {SESSION_NAME} from "../config/constants";

const AuthenticatedRoute = (props) =>{
  const dispatch = useDispatch();
  const profileStore = useSelector((state) => state.Profile);
  const token = getLoggedinUser();
  const location = useLocation();
  const {isError, isLoading, resultset, statusAction} = profileStore;

  useEffect(() => {
    if(token){
      setAuthorization(token);
      dispatch(processPostAuthMe());
    }
  }, [location.pathname]);

  if (!token || (isError && statusAction === 'error')) {
    Cookies.remove(SESSION_NAME);
    return (
      <Navigate to={{ pathname: AUTHENTICATION_SIGNIN, state: { from: props.location } }} />
    );
  }

  if(profileStore.isProcessDone){
    return React.cloneElement(props.children, {fromMe: resultset.data });
  }
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthenticatedRoute, AccessRoute };