import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GUEST_MY_APPLICATION,
  GUEST_REGISTER,
  GUEST_REGISTER_ACTUALIZATION,
  GUEST_REGISTER_PROFILE,
  GUEST_REGISTER_REPORT,
  GUEST_REGISTER_STATUS
} from "../config/url";

const Navdata = () => {
  const history = useNavigate();

  const [isDashboard, setIsDashboard] = useState(false);
  const [isMyApplication, setIsMyApplication] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isMaster, setIsMaster] = useState(false);
  const [isRegistration, setIsRegistration] = useState(false);
  const [isActualization, setIsActualization] = useState(false);
  const [isRegistrationStatus, setIsRegistrationStatus] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [isSetting, setIsSetting] = useState(false);

  const [isRegion, setIsRegion] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isElector, setIsElector] = useState(false);
  const [isRole, setIsRole] = useState(false);
  const [isDirector, setIsDirector] = useState(false);
  const [isCountry, setIsCountry] = useState(false);
  const [isWorkstation, setIsWorkstation] = useState(false);

  // Apps
  const [isEmail, setEmail] = useState(false);
  const [isSubEmail, setSubEmail] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isTasks, setIsTasks] = useState(false);
  const [isCRM, setIsCRM] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [isInvoices, setIsInvoices] = useState(false);
  const [isSupportTickets, setIsSupportTickets] = useState(false);
  const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
  const [isJobs, setIsJobs] = useState(false);
  const [isJobList, setIsJobList] = useState(false);
  const [isCandidateList, setIsCandidateList] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  // Pages
  const [isLanding, setIsLanding] = useState(false);

  // Charts
  const [isApex, setIsApex] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }

    if (iscurrentState !== "Master") {
      setIsMaster(false);
    }

    if (iscurrentState !== "Registration") {
      setIsRegistration(false);
    }

    if (iscurrentState !== "Report") {
      setIsReport(false);
    }

    if (iscurrentState !== "Setting") {
      setIsSetting(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isMaster,
    isRegistration,
    isReport,
    isSetting,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "my-application",
      label: "My Application",
      icon: " ri-file-paper-2-line",
      link: GUEST_MY_APPLICATION,
      stateVariables: isMyApplication,
    },
    {
      id: "profile",
      label: "Profile",
      icon: "ri-dashboard-2-line",
      link: GUEST_REGISTER_PROFILE,
      stateVariables: isProfile,
    },
    {
      id: "registration",
      label: "Online Registration",
      icon: "ri-ball-pen-line",
      link: GUEST_REGISTER,
      stateVariables: isRegistration,
    },
    {
      id: "registration-status",
      label: "Registration Report",
      icon: "ri-checkbox-multiple-blank-line",
      link: GUEST_REGISTER_STATUS,
      stateVariables: isReport,
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
