import withRouter from "../../Components/Common/withRouter";
import {Card, CardBody, CardHeader, Container, Input, Label, Row, Table} from "reactstrap";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import React from "react";
import {Link} from "react-router-dom";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import avtarImage1 from "../../../src/assets/images/users/avatar-1.jpg";
import avtarImage3 from "../../../src/assets/images/users/avatar-3.jpg";
import avtarImage4 from "../../../src/assets/images/users/avatar-4.jpg";
import avtarImage5 from "../../../src/assets/images/users/avatar-5.jpg";
import avtarImage6 from "../../../src/assets/images/users/avatar-6.jpg";
import { DefaultTable, PaginationTable, SearchTable, SortingTable, LoadingStateTable, HiddenColumns } from './ReactTable'
import { GUEST_REGISTER} from "../../config/url";

const MyApplication = (props) => {
  return (
      <>
        <div className="page-content">
          <Container fluid>
            <CustomBreadcrumb title="My Application" pageTitle="Pages"/>
            <Row>
              <Card className="rounded-0">
                <CardHeader>
                  <Link to={GUEST_REGISTER} className="btn-primary btn float-end fs-11 rounded-0 btn-sm">New Applicant</Link>
                  <h4 className="card-title mb-0 flex-grow-1">List Applicant</h4>
                </CardHeader>
                <CardBody className="card-body text-center pb-4">
                  <DefaultTable />
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </>
  )
}

export default withRouter(MyApplication)