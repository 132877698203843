import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Alert, Button, Card, CardBody, Col, Container, Input, Label, Row, Spinner,} from "reactstrap";
import withRouter from "../../Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";

import logostae from "../../assets/images/logo-stae.png";
import {CORP_NAME, SHORT_APP_NAME} from "../../config/constants";
import "cleave.js/dist/addons/cleave-phone.i18n";
import {AUTHENTICATION_SIGNIN} from "../../config/url";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {processPostRegister} from "../../states/authentication/register/thunk";
import FooterCopyright from "../authentication/FooterCopyright";

const RegisterPraElector = (props) => {
  const dispatch = useDispatch();
  const registerStore = useSelector((state) => state.Register);

  const [valueFullname, setValueFullname] = useState("");
  const [valueEMail, setValueEMail] = useState("");
  const [valuePhoneNumber, setValuePhoneNumber] = useState("");
  const [valuePhoneCode, setValuePhoneCode] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const [valuePasswordConfirmation, setValuePasswordConfirmation] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordConfirmationShow, setPasswordConfirmationShow] = useState(false);

  const validationInputRegistration = useFormik({
    enableReinitialize: true,

    initialValues: {
      fullname: valueFullname,
      email: valueEMail,
      phone_number: valuePhoneNumber,
      phone_code: valuePhoneCode,
      password: valuePassword,
      passwordConfirmation: valuePasswordConfirmation,
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required('Please enter your fullname'),
      email: Yup.string().email().required('Please enter your email'),
      phone_number: Yup.string().required('Please enter your phone number'),
      password: Yup.string().min(6, 'Password must be at least 6 characters').required('Please enter your password'),
      passwordConfirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Please confirm your password'),
    }),
    onSubmit: (values) => {
      values.phone_number = values.phone_number.replace(/\D/g, '', '');
      const data = {
        bindData: values,
        routerData: props.router
      }

      dispatch(processPostRegister(data))
    }
  })

  const handleAction = {
    onSubmit: (e) => {
      e.preventDefault();
      validationInputRegistration.handleSubmit();

      return false;
    },
  }

  if(registerStore.resultset === 'ok'){
    setTimeout(() => {
      window.location.replace(AUTHENTICATION_SIGNIN)
    }, 5000);
  }

  return (
      <>
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <Container>
            <Row className="justify-content-center">
              <Col xl={5}>
                <Card className="mt-0 mb-0 rounded-0 shadow-lg shadow shadow-sm border-primary" style={{borderTop: "3px solid"}}>
                  <CardBody className="p-3">
                    <div className="text-center mt-2 text-primary">
                      <div>
                        <Link to="/" className="d-inline-block auth-logo">
                          <img src={logostae} alt="" height={110}/>
                        </Link>
                      </div>
                      <p className="mt-3 fs-15 fw-medium text-uppercase">{CORP_NAME}</p>
                    </div>
                    <div className="border mt-3 mb-3 border-dashed"></div>
                    <div className="text-center mt-2">
                      <h4 className="text-primary text-uppercase">Account Registration</h4>
                    </div>
                    <div className="p-2 mt-0">
                      {registerStore.isError ? (
                          <Alert color="danger" className="rounded-0 py-2">{registerStore.errorset}</Alert>) : null}
                      {
                        registerStore.resultset === 'ok' ?
                            (
                                <>
                                  <div className="avatar-lg mx-auto mt-2">
                                    <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                      <i className="ri-checkbox-circle-fill"></i>
                                    </div>
                                  </div>
                                  <div className="mt-4 pt-2 text-center">
                                    <h4>Well done !</h4>
                                    <p className="text-muted mx-4">Please check your inbox to get
                                      verification
                                      link.</p>
                                    <div className="mt-4">
                                      <Link to={AUTHENTICATION_SIGNIN} className="btn btn-success w-100 rounded-0">Back
                                        to
                                        Sign In Page</Link>
                                    </div>
                                  </div>
                                </>
                            ) :
                            (
                                <form autoComplete="off" onSubmit={(e) => handleAction.onSubmit(e)}>
                                  <div className="mb-3">
                                    <Label htmlFor="fullname" className="form-label">Naran Kompletu</Label>
                                    <Input tabIndex="1" type="text" className="form-control rounded-0" id="fullname"
                                           placeholder="Enter fullname"
                                           onChange={(e) => setValueFullname(e.target.value)}
                                           autoFocus/>
                                  </div>

                                  <Row>
                                    <Col xl={12}>
                                      <div className="mb-3">
                                        <Label htmlFor="email" className="form-label">Enderesu Eletroniku</Label>
                                        <Input tabIndex="2" type="text" className="form-control rounded-0" id="email"
                                               placeholder="Enter email address"
                                               onChange={(e) => setValueEMail(e.target.value)}
                                               invalid={validationInputRegistration.touched.email && validationInputRegistration.errors.email}/>
                                        <div
                                            className="invalid-feedback">{validationInputRegistration.errors.email}</div>
                                      </div>
                                    </Col>
                                    <Col xl={12}>
                                      <div className="mb-3">
                                        <Label htmlFor="phonenumber">Numeru Telefone</Label>
                                        <PhoneInput
                                            international
                                            countryCallingCodeEditable={false}
                                            defaultCountry="TL"
                                            onChange={setValuePhoneNumber}
                                            value={valuePhoneNumber}
                                            className="form-control rounded-0"
                                            tabIndex={3}
                                        />
                                      </div>
                                    </Col>
                                    <Col xl={6}>
                                      <div className="mb-0">
                                        <Label className="form-label" htmlFor="password">Liafuan Xave</Label>
                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                          <Input tabIndex="4" type={passwordShow ? "text" : "password"}
                                                 className="form-control pe-5 password-input rounded-0" id="password"
                                                 onChange={(e) => setValuePassword(e.target.value)}
                                                 invalid={validationInputRegistration.touched.password && validationInputRegistration.errors.password}/>
                                          <div
                                              className="invalid-feedback">{validationInputRegistration.errors.password}</div>
                                          <button
                                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                              type="button" id="password-addon"
                                              onClick={() => setPasswordShow(!passwordShow)}><i
                                              className="ri-eye-fill align-middle"></i></button>
                                        </div>

                                      </div>
                                    </Col>
                                    <Col xl={6}>
                                      <div className="mb-0">
                                        <Label className="form-label" htmlFor="passwordConfirmation">Konfima Liafuan Xave </Label>
                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                          <Input tabIndex="5" type={passwordConfirmationShow ? "text" : "password"}
                                                 className="form-control pe-5 password-input rounded-0"
                                                 id="passwordConfirmation"
                                                 onChange={(e) => setValuePasswordConfirmation(e.target.value)}
                                                 invalid={validationInputRegistration.touched.passwordConfirmation && validationInputRegistration.errors.passwordConfirmation}/>
                                          <div
                                              className="invalid-feedback">{validationInputRegistration.errors.passwordConfirmation}</div>
                                          <button
                                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                              type="button" id="password-addon-confirmation"
                                              onClick={() => setPasswordConfirmationShow(!passwordConfirmationShow)}><i
                                              className="ri-eye-fill align-middle"></i></button>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <div className="mt-1">
                                    <Button color="primary" className="btn btn-primary w-100 rounded-0" type="submit"
                                            disabled={registerStore.isLoading}>
                                      {registerStore.isLoading &&
                                          <Spinner size="sm" className='me-2'> Loading... </Spinner>}
                                      Rejistu
                                    </Button>
                                  </div>
                                </form>
                            )
                      }
                    </div>
                  </CardBody>
                </Card>

                {registerStore.resultset !== 'ok' && (
                    <>
                      <div className="mt-2 text-center">
                        <p className="mb-0">Already have account?
                          <Link to={AUTHENTICATION_SIGNIN}
                                className="fw-semibold text-primary"> Sign In </Link>
                        </p>

                        <p className="mb-0 mt-2">
                          <Link to="/"
                                className="fw-semibold text-primary text-decoration-underline"> Filla ba pajina </Link>
                        </p>
                      </div>
                    </>
                )}
                <FooterCopyright />
              </Col>
            </Row>
          </Container>
        </div>
      </>
  );
};

export default withRouter(RegisterPraElector);