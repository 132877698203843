import React, {useState} from 'react';
import withRouter from "../../Components/Common/withRouter";
import {APP_NAME, CORP_NAME, SHORT_APP_NAME, SHORT_CORP_NAME, TITLE} from "../../config/constants";
import {AUTHENTICATION_REGISTER, AUTHENTICATION_SIGNIN} from "../../config/url";
import bg_starter from "../../assets/images/bg-starter.jpeg";

const Starter = (props) => {
  console.log(props)
  return (
      <>
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
          <div class="container px-5">
            {/*<img src={logo} width="50" alt="logo" className="me-3" />*/}
            <a className="navbar-brand fw-bolder" href="#!">{SHORT_CORP_NAME}</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item"><a className="nav-link active" href={AUTHENTICATION_SIGNIN}>Tama</a></li>
                <li className="nav-item"><a className="nav-link active" href={AUTHENTICATION_REGISTER}>Pedido Online</a></li>
              </ul>
            </div>
          </div>
        </nav>

        <header class="py-5" style={{background: `#13467F url(${bg_starter}) no-repeat center top`}}>
          <div class="container px-0 py-0">
            <div class="row gx-0 justify-content-center">
              <div class="col-lg-9">
                <div class="text-center" style={{marginTop: '160px'}}>
                  <h1 class="display-4 fw-bolder text-white mb-2 text-uppercase">Pedido Online Resenseamento Eleitoral</h1>
                  <p class="lead text-white mb-4">Sistema Informasaun Resenseamento Eleitoral - Biometrik.</p>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section class="py-5 border-bottom" id="features">
          <div class="container px-5 my-5">
            <div class="row gx-5">
              <div class="col-lg-4 mb-5 mb-lg-0">
                <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-collection"></i>
                </div>
                <h2 class="h4 fw-bolder mb-4">Kontatu</h2>
                <p className="mb-2">Lahane Oriental</p>
                <p className="mb-2">Nain Feto, Dili, Timor Leste</p>
                <p className="mb-2">+670 77349712</p>
              </div>
            </div>
          </div>
        </section>
        <footer class="py-5 bg-dark">
          <div class="container px-5"><p
            class="m-0 text-center text-white">Copyright &copy; {SHORT_CORP_NAME} | {SHORT_APP_NAME} - {APP_NAME}</p>
          </div>
        </footer>
      </>
  )
}

export default withRouter(Starter);