import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import {SESSION_NAME} from "../../config/constants";
import Cookies from "js-cookie";
import {useDispatch, useSelector} from "react-redux";
import {processPostSignOut} from "../../states/authentication/signout/thunk";

const SignOut = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(processPostSignOut());
  }, []);

  Cookies.remove(SESSION_NAME);
  return <Navigate to="/starter" />
}

export default withRouter(SignOut);