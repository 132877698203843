import { createSlice } from "@reduxjs/toolkit";
import {ASYNC_STATUSES, DEFAULT_REDUCER_INITIAL_VALUE} from "../../../config/constants";
import {
  processGetMasterAldeia,
  processGetMasterDistricts,
  processGetMasterSubdistricts,
  processGetMasterSucco, processPostCheckElector,
  processPostRegisterOnline
} from "./thunk";

const initialState = DEFAULT_REDUCER_INITIAL_VALUE;
const registerOnlineStore = createSlice({
  name: "registerOnlineStore",
  initialState,
  extraReducers: (builder) => {
    builder
        .addCase(processPostRegisterOnline.pending, (state, action) => {
          state.isLoading = true;
          state.isError = false;
          state.statusAction = ASYNC_STATUSES.pending;
          state.showNotification.isShow = false;
          state.showNotification.notificationMessage = "";
        })
        .addCase(processPostRegisterOnline.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.statusAction = ASYNC_STATUSES.fullfilled;
          state.resultset = action.payload;
          state.isProcessDone = true;
          state.showNotification.isShow = true;
        })
        .addCase(processPostRegisterOnline.rejected, (state, action) => {
          const unstringifyError = JSON.parse(action.payload)

          state.isLoading = false;
          state.isError = true;
          state.isProcessDone = true;
          state.statusAction = ASYNC_STATUSES.reject;
          state.httpCode = unstringifyError.status;
          state.errorset = unstringifyError.data.message;
          state.showNotification.isShow = true;
        })

        .addCase(processGetMasterSubdistricts.pending, (state, action) => {
          state.isLoading = true;
          state.isError = false;
          state.statusAction = ASYNC_STATUSES.pending;
          state.showNotification.isShow = false;
          state.showNotification.notificationMessage = "";
        })
        .addCase(processGetMasterSubdistricts.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.statusAction = ASYNC_STATUSES.fullfilled;
          state.resultset = {...state.resultset, ...action.payload};
          state.isProcessDone = true;
        })
        .addCase(processGetMasterSubdistricts.rejected, (state, action) => {
          const unstringifyError = JSON.parse(action.payload) || 'error'

          state.isLoading = false;
          state.isError = true;
          state.isProcessDone = true;
          state.statusAction = ASYNC_STATUSES.reject;
          state.httpCode = 400;
          state.errorset = 'error';
          state.showNotification.isShow = true;
        })

        .addCase(processGetMasterDistricts.pending, (state, action) => {
          state.isLoading = true;
          state.isError = false;
          state.statusAction = ASYNC_STATUSES.pending;
          state.showNotification.isShow = false;
          state.showNotification.notificationMessage = "";
        })
        .addCase(processGetMasterDistricts.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isProcessDone = true;
          state.statusAction = ASYNC_STATUSES.fullfilled;
          state.resultset = {...state.resultset, ...action.payload};
        })
        .addCase(processGetMasterDistricts.rejected, (state, action) => {
          const unstringifyError = JSON.parse(action.payload) || 'error'

          state.isLoading = false;
          state.isError = true;
          state.isProcessDone = true;
          state.statusAction = ASYNC_STATUSES.reject;
          state.httpCode = 400;
          state.errorset = 'error';
          state.showNotification.isShow = true;
        })

        .addCase(processGetMasterSucco.pending, (state, action) => {
          state.isLoading = true;
          state.isError = false;
          state.statusAction = ASYNC_STATUSES.pending;
          state.showNotification.isShow = false;
          state.showNotification.notificationMessage = "";
        })
        .addCase(processGetMasterSucco.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isProcessDone = true;
          state.statusAction = ASYNC_STATUSES.fullfilled;
          state.resultset = {...state.resultset, ...action.payload};
        })
        .addCase(processGetMasterSucco.rejected, (state, action) => {
          const unstringifyError = JSON.parse(action.payload) || 'error'

          state.isLoading = false;
          state.isError = true;
          state.isProcessDone = true;
          state.statusAction = ASYNC_STATUSES.reject;
          state.httpCode = 400;
          state.errorset = 'error';
          state.showNotification.isShow = true;
        })

        .addCase(processGetMasterAldeia.pending, (state, action) => {
          state.isLoading = true;
          state.isError = false;
          state.statusAction = ASYNC_STATUSES.pending;
          state.showNotification.isShow = false;
          state.showNotification.notificationMessage = "";
        })
        .addCase(processGetMasterAldeia.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isProcessDone = true;
          state.statusAction = ASYNC_STATUSES.fullfilled;
          state.resultset = {...state.resultset, ...action.payload};
        })
        .addCase(processGetMasterAldeia.rejected, (state, action) => {
          const unstringifyError = JSON.parse(action.payload) || 'error'

          state.isLoading = false;
          state.isError = true;
          state.isProcessDone = true;
          state.statusAction = ASYNC_STATUSES.reject;
          state.httpCode = 400;
          state.errorset = 'error';
          state.showNotification.isShow = true;
        })

        .addCase(processPostCheckElector.pending, (state, action) => {
          state.isLoading = true;
          state.isError = false;
          state.statusAction = ASYNC_STATUSES.pending;
          state.showNotification.isShow = false;
          state.showNotification.notificationMessage = "";
        })
        .addCase(processPostCheckElector.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isProcessDone = true;
          state.statusAction = ASYNC_STATUSES.fullfilled;
          state.resultset = {...state.resultset, ...action.payload};
        })
        .addCase(processPostCheckElector.rejected, (state, action) => {
          const unstringifyError = JSON.parse(action.payload) || 'error'

          state.isLoading = false;
          state.isError = true;
          state.isProcessDone = true;
          state.statusAction = ASYNC_STATUSES.reject;
          state.httpCode = 400;
          state.errorset = 'error';
          state.showNotification.isShow = true;
        })
  }
})

export default registerOnlineStore.reducer;