import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import {APIClient} from "../../../helpers/api_helper";
import {BASE_API_URL, SESSION_NAME} from "../../../config/constants";
import {GUEST_REGISTER_PROFILE} from "../../../config/url";

const api = new APIClient();

export const processGetMasterDistricts = createAsyncThunk("processGetMasterDistricts", async (data, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = api.get(`${BASE_API_URL}master/districts`)
    const result = await response;

    if(result){
      const list = result?.data;
      const getDistrict = list.map(item => ({
        value: item.code_district,
        label: item.name
      }));

      return {
        region: {
          'inputDomicileDistrict': getDistrict,
          'inputOriginDistrict': getDistrict
        }

      }
    }
  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});

export const processGetMasterSubdistricts = createAsyncThunk("processGetMasterSubdistricts", async (data, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = api.get(`${BASE_API_URL}master/subdistricts`)
    const result = await response;

    if(result){
      const list = result?.data;
      const {bindData, routerData, typeRegion} = data;
      const getSelectedDistrict = bindData.selectedDistrict;

      const filterSubdistricts = list.filter(item => item.code_district === getSelectedDistrict);
      const getSelectedSubdistrict = filterSubdistricts.map(item => ({
        value: item.code_subdistrict,
        label: item.name
      }));

      const prevState = getState();
      const getCurrentState = prevState['RegisterOnline']['resultset']['region'];

      return {
        region: {...getCurrentState, [typeRegion]: getSelectedSubdistrict}
      }
    }
  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});

export const processGetMasterSucco = createAsyncThunk("processGetMasterSucco", async (data, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = api.get(`${BASE_API_URL}master/urban-villages`)
    const result = await response;

    if(result){
      const list = result?.data;
      const {bindData, routerData, typeRegion} = data;
      const getSelectedSubdistrict = bindData.selectedSubdistrict;
      const filterSubdistricts = list.filter(item => item.code_subdistrict === getSelectedSubdistrict && item.name !== null);
      const getSelectedSucco = filterSubdistricts.map(item => ({
        value: item.code_urban_village,
        label: item.name
      }));

      const prevState = getState();
      const getCurrentState = prevState['RegisterOnline']['resultset']['region'];

      return {
        region: {...getCurrentState, [typeRegion]: getSelectedSucco}
      }
    }
  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});

export const processGetMasterAldeia = createAsyncThunk("processGetMasterAldeia", async (data, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = api.get(`${BASE_API_URL}master/villages`)
    const result = await response;

    if(result){
      const list = result?.data;
      const {bindData, routerData, typeRegion} = data;
      const getSelectedSucco = bindData.selectedSucco;
      const filterSucco = list.filter(item => item.code_urban_village === getSelectedSucco && item.name !== null);
      const getSelectedAldeia = filterSucco.map(item => ({
        value: item.code_village,
        label: item.name
      }));

      const prevState = getState();
      const getCurrentState = prevState['RegisterOnline']['resultset']['region'];

      return {
        region: {...getCurrentState, [typeRegion]: getSelectedAldeia}
      }
    }
  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});

export const processPostRegisterOnline = createAsyncThunk("processPostRegisterOnline", async (data, { rejectWithValue, dispatch }) => {
  try {
    const {bindData, routerData} = data;
    const {fileDocuments, textValues} = bindData;

    const formData = new FormData();

    Object.entries(textValues).forEach(([key, value]) => {
      formData.append(key, value);
    });

    fileDocuments.forEach((file, index) => {
      formData.append(`document${index}`, file);
    });

    const response = api.create(`${BASE_API_URL}master/electors/add`, formData)
    const result = await response;

    if(result){
      return 'ok'
    }

  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});

export const processPostCheckElector = createAsyncThunk("processPostCheckElector", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = api.create(`${BASE_API_URL}master/electors/check`)
    const result = await response;

    if(result){
      return {
        dataElectorRegistered: result['data'],
      };
    }
  } catch (error){
    const stringifyError = JSON.stringify(error);
    return rejectWithValue(stringifyError);
  }
});