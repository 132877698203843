import React, {useEffect, useRef, useState} from 'react';
import withRouter from "../../Components/Common/withRouter";
import CustomBreadcrumb from "../../Components/Common/CustomBreadcrumb";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label, Modal, ModalBody, ModalHeader,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import Flatpickr from "react-flatpickr";
import country from "../../Components/Common/CountryData";

import { FilePond,  registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import {calculateAge, generateFlatpickrFormat, getSessionStorage} from "../../helpers/general_helper";
import {useDispatch, useSelector} from "react-redux";
import {
  processGetMasterAldeia,
  processGetMasterDistricts,
  processGetMasterSubdistricts, processGetMasterSucco, processPostCheckElector
} from "../../states/authentication/register-online-actualization/thunk";
import Select from 'react-select'
import SimpleBar from "simplebar-react";
import {useFormik} from "formik";
import {Link} from "react-router-dom";
import {GUEST_REGISTER, GUEST_REGISTER_STATUS} from "../../config/url";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const RegisterOnlineActualization = (props) => {
  const {actionOnInputPortugueseFocused, actionOnInputPortugueseChange, actionOnInputPortugueseValue} = props;
  const dispatch = useDispatch();
  const registerOnlineStoreActualization = useSelector((state) => state.RegisterOnlineActualization);
  const statusRegister = 0;

  useEffect(() => {
    dispatch(processGetMasterDistricts());
    dispatch(processPostCheckElector());
  }, []);

  useEffect(() => {

  }, []);

  const [showModalNotification, setShowModalNotification] = useState(false);

  const [inputCommonData, setInputCommonData] = useState({});
  const [listRegions, setListRegions] = useState({});
  const [valueCountry, setValueCountry] = useState("");

  const [selectedFiles, setselectedFiles] = useState([]);
  const [isBornInTimor, setIsBornInTimor] = useState(true);
  const [filePhotos, setFilePhotos] = useState([]);
  const [fileDocuments, setFileDocuments] = useState([]);

  const [dropdownCountryCode, setDropdownCountryCode] = useState(false);

  /* Reff */
  const inputRefs = useRef({});

  const validationSubmit = useFormik({
    enableReinitialize: true,

    initialValues: {

    },
    onSubmit: () => {
      const inputFiles = [];
      fileDocuments.forEach((singleFile) => {
        inputFiles.push(singleFile.file);
      })

      const fromRegister = {
        email: getSessionStorage().email,
        fullname: getSessionStorage().fullname,
        itx: getSessionStorage().itx,
      }
      const getAllInputValue = {...inputCommonData, ...actionOnInputPortugueseValue, inputFiles, ...fromRegister};
      const data = {
        bindData: getAllInputValue,
        routerData: props.router
      }

      dispatch(processPostRegisterOnline(data))
    }
  });

  const handleAction = {
    toggleCountryCode: () => setDropdownCountryCode((prevState) => !prevState),
    toggleIsBornInTimor: (e) => {
      setIsBornInTimor(!isBornInTimor);
    },
    onInputCommonDataChange: (inputId, value, isInputRegion=false) => {
      setInputCommonData(prevState => ({
        ...prevState,
        [inputId]: value
      }));

      if(isInputRegion){
        const listBehavior = {
          'inputDomicileDistrict': {
            dispatcher: (data) => processGetMasterSubdistricts(data),
            bindData: {selectedDistrict: value},
            nextDataRegion: 'inputDomicileSubDistrict'
          },
          'inputDomicileSubDistrict': {
            dispatcher: (data) => processGetMasterSucco(data),
            bindData: {selectedSubdistrict: value},
            nextDataRegion: 'inputDomicileSucco'
          },
          'inputDomicileSucco': {
            dispatcher: (data) => processGetMasterAldeia(data),
            bindData: {selectedSucco: value},
            nextDataRegion: 'inputDomicileAldeia'
          },

          'inputOriginDistrict': {
            dispatcher: (data) => processGetMasterSubdistricts(data),
            bindData: {selectedDistrict: value},
            nextDataRegion: 'inputOriginSubDistrict'
          },
          'inputOriginSubDistrict': {
            dispatcher: (data) => processGetMasterSucco(data),
            bindData: {selectedSubdistrict: value},
            nextDataRegion: 'inputOriginSucco'
          },
          'inputOriginSucco': {
            dispatcher: (data) => processGetMasterAldeia(data),
            bindData: {selectedSucco: value},
            nextDataRegion: 'inputOriginAldeia'
          },
        }

        const hasBehavior = inputId in listBehavior;
        if(hasBehavior){
          const getBehavior = listBehavior[inputId];

          const data = {
            bindData: getBehavior['bindData'],
            routerData: props.router,
            typeRegion: getBehavior['nextDataRegion']
          }

          dispatch(getBehavior['dispatcher'](data))
        }
      }
    },
    onFocusInputRegion: (inputId,typeRegion) => {
      const getRegionFromStore = registerOnlineStoreActualization.resultset.region;
      const setKeyName = `${inputId}_list`;

      setListRegions(prevState => ({
        ...prevState,
        [setKeyName]: getRegionFromStore[inputId]
      }));
    },
    onSubmit: (e) => {
      e.preventDefault();

      validationSubmit.handleSubmit();
    }
  };

  useEffect(() => {
    setShowModalNotification(registerOnlineStoreActualization.showNotification.isShow)
  }, [registerOnlineStoreActualization]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <CustomBreadcrumb title="Actualization" pageTitle="Pages"/>
          <Row>
            <Col xl={12}>
              <Card className="rounded-0">
                <PreviewCardHeader title="Actualization Elector Registration" />
                <CardBody className="card-body">
                  <form autoComplete="off" onSubmit={(e) => handleAction.onSubmit(e)}>
                    <Col xl={12}>
                      <Row>
                        <Col xl={6}>
                          <Row className="gy-1">
                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputNoElector" className="form-label mb-1">No. Elector</Label>
                                <Input type="text" className="form-control rounded-0" id="inputNoElector"
                                       value={inputCommonData['inputNoElector']}
                                       onChange={(e) => handleAction.onInputCommonDataChange('inputNoElector', e.target.value)}
                                />
                              </div>
                            </Col>

                            <Col xl={12}>
                              <div>
                                <Label htmlFor="inputFullname" className="form-label mb-1">Naran Kompletu</Label>
                                <Input type="text" className="form-control rounded-0"
                                       value={registerOnlineStoreActualization.resultset?.dataElectorRegistered?.fullname} disabled/>
                              </div>
                            </Col>

                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputFullname" className="form-label mb-1">Numeru Telefone</Label>
                                <Input type="text" className="form-control rounded-0"
                                       value={registerOnlineStoreActualization.resultset?.dataElectorRegistered?.phone_number}/>
                              </div>
                            </Col>

                            <Col xl={6}>
                              <div>
                                <Label htmlFor="inputDOB" className="form-label mb-1">Loron Moris</Label>
                                <Input type="text" className="form-control rounded-0"
                                       value={registerOnlineStoreActualization.resultset?.dataElectorRegistered?.dob}/>
                              </div>
                            </Col>

                            <Col xl={12}>
                              <div>
                                <Label htmlFor="inputFatherName" className="form-label mb-1">Aman nia Naran</Label>
                                <Input type="text" className="form-control rounded-0"
                                       value={registerOnlineStoreActualization.resultset?.dataElectorRegistered?.father_name}/>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                    </Col>
                    <Col lg={12}>
                      <div className="text-start mt-4">
                        <Button color="primary" className="btn btn-primary rounded-0" type="submit">Check</Button>
                      </div>
                    </Col>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={showModalNotification}
        toggle={() => {
          setShowModalNotification(!showModalNotification)
        }}
        centered
        backdrop={'static'}
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-5 rounded-0">
          <lord-icon src="https://cdn.lordicon.com/hrqwmuhr.json"
                     trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: "120px", height: "120px" }}>
          </lord-icon>
          <div className="mt-4">
            <h4 className="mb-3">Oops something went wrong!</h4>
            <p className="text-muted mb-4"> The transfer was not successfully received by us. the email of the recipient wasn't correct.</p>
            <div className="hstack gap-2 justify-content-center">
              <Button color="light" className="rounded-0" onClick={() => setShowModalNotification(false)}>Close</Button>
              <a href={GUEST_REGISTER} className="btn btn-danger rounded-0">Try Again</a>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default withRouter(RegisterOnlineActualization);