import {APP_NAME, SHORT_APP_NAME, SHORT_CORP_NAME} from "../../config/constants";
import React from "react";

const FooterCopyright = () => {
  return (
      <div className="container px-0 mt-5">
        <p className="m-0 text-center text-primary text-uppercase"
           style={{fontSize: "11px"}}>Copyright &copy; {SHORT_CORP_NAME} 2024</p>
        <p className="m-0 text-center text-primary text-uppercase"
           style={{fontSize: "11px"}}>{SHORT_APP_NAME} ~ {APP_NAME}</p>
      </div>
  )
}

export default FooterCopyright;