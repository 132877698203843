export const CORP_NAME = 'Secretariado Técnico de Administração Eleitoral'
export const SHORT_CORP_NAME = 'STAE'
export const TITLE = `${SHORT_CORP_NAME} - ${CORP_NAME}`
export const APP_NAME = `Sistema Informasaun Resenseamento Eleitoral`
export const SHORT_APP_NAME = `SIRE`

export const SESSION_NAME = 'STAEUserAuthentication'

export const BASE_API_URL = process.env.REACT_APP_API_URL

export const ASYNC_STATUSES = {pending: "pending", fullfilled: "success", reject: "error"};
export const DEFAULT_REDUCER_INITIAL_VALUE = {
  isLoading: false,
  isProcessDone: false,
  resultset: {},
  httpCode: 200,
  isError: false,
  errorset: {},
  statusAction: "initial",
  actionFrom: "default",
  showNotification: {isShow: false, notificationMessage: ""},
  others: {}
}

export const PORTUGUESES_CHARACTERS = [
  'á', 'é', 'í', 'ó', 'ú',
  'à', 'è', 'ì', 'ò', 'ù',
  'ã', 'õ',
  'â', 'ê', 'î', 'ô', 'û',
  'ç'
];